import React from "react"
import { graphql } from "gatsby"

import GlobalLayout from "./global-layout"
import SEO from "../components/seo"
import PostsPagination from "../components/posts-pagination"
import PostExcerpt from "../components/post-excerpt"

export default function PaginationLayout ({ data, pageContext }) {
  const {pageNum, lastPageNum} = pageContext
  return (
    <GlobalLayout>
      <SEO
        title="All Posts"
        meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      {data.allFile.edges.map(({node}, idx) => {
        return (
          <PostExcerpt
            key={idx}
            createdDate={node.childMdx.frontmatter.date}
            name={node.name}
            title={node.childMdx.frontmatter.title}
            excerpt={node.childMdx.excerpt}
          />
        )
      })}
      <PostsPagination currentPageNum={pageNum} lastPageNum={lastPageNum} />
    </GlobalLayout>
  );
}

export const query = graphql`
query PaginationLayoutQuery($maxPostsInPage: Int, $skipCount: Int) {
  allFile(
    filter: {sourceInstanceName: {eq: "posts"}, internal: {mediaType: {eq: "text/mdx"}}}
    limit: $maxPostsInPage
    skip: $skipCount
    sort: {fields: childMdx___frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        name
        childMdx {
          frontmatter {
            title
            date
          }
          excerpt(truncate: true)
        }
      }
    }
  }
}
`
