import React from "react"
import {Link} from "gatsby"
import PropTypes from "prop-types"
import Pagination from "@material-ui/lab/Pagination"
import PaginationItem from '@material-ui/lab/PaginationItem';

import * as styles from "./posts-pagination.module.css"

function PostsPagination (props) {
  return (
    <div className={styles.postsPagination}>
      <Pagination
        page={props.currentPageNum}
        count={props.lastPageNum}
        renderItem={(item) => (
          <PaginationItem
            component={Link}
            to={`/page/${item.page}/`}
            {...item}
          />
        )}
        showFirstButton
        showLastButton
      />
    </div>
  )
}

PostsPagination.defaultProps = {
  currentPageNum: 1,
  lastPageNum: 1,
}

PostsPagination.propTypes = {
  currentPageNum: PropTypes.number,
  lastPageNum: PropTypes.number,
}

export default PostsPagination
